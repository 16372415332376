import React from 'react';
import slugify from 'react-slugify';

import Markdown from 'components/common/markdown/Markdown';
import companyAddressGuideHook from 'hooks/company-address-guide/company-address-guide.hook';

import {
  companyAddressGuide__heading,
  companyAddressGuide__wrapper,
  companyAddressGuide__table,
  companyAddressGuide__row,
  companyAddressGuide__row___header,
  companyAddressGuide__cell,
  companyAddressGuide__cell___question,
  companyAddressGuide__footnote,
} from './CompanyAddressGuide.scss';

export default (): JSX.Element => {
  const companyAddressGuide = companyAddressGuideHook();

  return (
    <div>
      {companyAddressGuide.map(
        ({ node }): JSX.Element => (
          <div key={`company-address-guide-section-${slugify(node.heading)}`}>
            <h2 className={companyAddressGuide__heading}>
              <Markdown source={node.heading} />
            </h2>

            <div className={companyAddressGuide__wrapper}>
              <div className={companyAddressGuide__table}>
                <div className={`${companyAddressGuide__row} ${companyAddressGuide__row___header}`}>
                  <div className={`${companyAddressGuide__cell} ${companyAddressGuide__cell___question}`} />
                  {node.headers.map(
                    (header): JSX.Element => (
                      <div
                        key={`${slugify(node.heading)}-company-address-${node.slug}-header}`}
                        className={companyAddressGuide__cell}
                      >
                        <h4>
                          <Markdown source={header.name} />
                        </h4>
                      </div>
                    )
                  )}
                </div>
                {node.rows.map(
                  (row): JSX.Element => (
                    <div
                      key={`${slugify(node.heading)}-company-address-${node.slug}-row`}
                      className={companyAddressGuide__row}
                    >
                      <div className={`${companyAddressGuide__cell} ${companyAddressGuide__cell___question}`}>
                        <Markdown source={row.question} />
                      </div>
                      <div className={companyAddressGuide__cell}>
                        <Markdown source={row.registered_office} />
                      </div>
                      <div className={companyAddressGuide__cell}>
                        <Markdown source={row.service_address} />
                      </div>
                      <div className={companyAddressGuide__cell}>
                        <Markdown source={row.business_address} />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className={companyAddressGuide__footnote}>
              <Markdown source={node.footnote} container />
            </div>
          </div>
        )
      )}
    </div>
  );
};
